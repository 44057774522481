import React from 'react';
import { Helmet } from 'react-helmet';

function Links() {
  return (
    <>
      <Helmet>
      <script src="https://shop.live911th.com/img/img/app.js"></script>
   
      </Helmet>
      <div class="img"><a id="btn"><div id="img"></div></a></div>
    </>
  );
}

export default Links;
